import { getUserData } from '@/auth/utils'

export default [
  {
    title: 'Insights',
    route: 'home',
    icon: 'TrendingUpIcon',
    action: 'read',
    resource: 'Home',
  },
  {
    title: 'Instituições',
    route: 'institution-list',
    icon: 'MapPinIcon',
    tagVariant: 'light-success',
    action: 'read',
    resource: 'Institution',
  },
  {
    title: 'Médicos',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Todos',
        icon: 'UsersIcon',
        route: 'physician-list',
        action: 'read',
        resource: 'Physician',
      },
      {
        title: 'Novos',
        route: 'report-physicians',
        icon: 'ClockIcon',
        action: 'read',
        resource: 'ReportPhysicians',
      },
      {
        title: 'Primeira Antecipação',
        route: 'report-physician-first-advance',
        icon: 'AwardIcon',
        action: 'read',
        resource: 'ReportPhysicianFirstAdvance',
      },
      {
        title: 'Ativado',
        route: 'report-physician-activate',
        icon: 'CheckCircleIcon',
        action: 'read',
        resource: 'ReportPhysicianActivate',
      },
      {
        title: 'Verificações CFM',
        route: 'cfm-physician-list',
        icon: 'UserCheckIcon',
        action: 'read',
        resource: 'CfmPhysician',
      },
    ],
  },
  {
    title: 'Class. de plantão',
    route: 'duty-type',
    icon: 'AwardIcon',
    action: 'read',
    resource: 'DutyType',
  },
  {
    title: 'Processos',
    href: () => {
      const user = getUserData()
      return user ? `https://${user.aws_token.user}:${user.aws_token.password}@processo.imediato.online` : ''
    },
    icon: 'SettingsIcon',
    action: 'read',
    resource: 'BusinessProcess',
    target: '_blank',
  },
  {
    title: 'Relatórios',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Adiantamentos',
        route: 'reports',
        icon: 'FileTextIcon',
        action: 'read',
        resource: 'ReportAdvancePayment',
      },
      {
        title: 'Lançamentos',
        route: 'reports-physician-duty',
        icon: 'UsersIcon',
        action: 'read',
        resource: 'ReportPhysicianDuty',
      },
      {
        title: 'Histórico de Contas',
        route: 'account-history',
        icon: 'SearchIcon',
        action: 'read',
        resource: 'Audit',
      },
      {
        title: 'Auditoria',
        route: 'audits',
        icon: 'SearchIcon',
        action: 'read',
        resource: 'Audit',
      },
      {
        title: 'Plantões x Folha',
        route: 'physician-duty-on-payroll',
        icon: 'FileTextIcon',
        action: 'read',
        resource: 'ReportPhysicianDutyOnPayroll',
      },
      {
        title: 'Conflitos',
        route: 'physician-duty-conflict',
        icon: 'AlertCircleIcon',
        action: 'read',
        resource: 'ReportPhysicianDutyConflict',
      },
      {
        title: 'Indicadores de Vendas',
        route: 'charts',
        icon: 'BarChart2Icon',
        action: 'read',
        resource: 'Charts',
      },

    ],
  },
  {
    header: 'Financeiro',
    action: 'Financial',
    resource: 'Financial',
  },
  {
    title: 'Faturas',
    route: 'invoice',
    icon: 'DollarSignIcon',
    action: 'read',
    resource: 'Invoice',
  },
  {
    title: 'Solicitações',
    route: 'not-in-payment-remittance',
    icon: 'UsersIcon',
    action: 'read',
    resource: 'NotInPaymentRemittances',
  },
  {
    title: 'Remessas',
    route: 'payment-remittance-list',
    icon: 'CheckCircleIcon',
    action: 'read',
    resource: 'PaymentRemittances',
  },
  {
    title: 'Relatórios ',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Adiantamentos',
        route: 'financial-reports',
        icon: 'FileTextIcon',
        action: 'read',
        resource: 'FinancialReportAdvancePayment',
      },
      {
        title: 'Lucros x Adiantamentos',
        route: 'report-profit',
        icon: 'FileTextIcon',
        action: 'read',
        resource: 'ReportProfit',
      },
    ],
  },
  {
    title: 'Bancos',
    route: 'financial-institution-list',
    icon: 'HomeIcon',
    action: 'read',
    resource: 'FinancialInstitutions',
  },
  {
    header: 'Integrações',
    action: 'Integrations',
    resource: 'Integrations',
  },
  {
    title: 'Importações',
    icon: 'UploadCloudIcon',
    action: 'read',
    resource: 'Import',
    children: [
      {
        title: 'Médicos',
        route: 'import-physician',
        icon: 'UsersIcon',
        action: 'read',
        resource: 'ImportPhysician',
      },
    ],
  },
  {
    title: 'Erro sincronização',
    route: 'sync-errors',
    icon: 'RefreshCcwIcon',
    action: 'read',
    resource: 'ReportSyncErrors',
  },
  {
    title: 'Erro plantão',
    route: 'failed-physician-duties',
    icon: 'ArrowDownIcon',
    action: 'read',
    resource: 'ReportFailedPhysicianDuties',
  },
  {
    header: 'Admin',
    action: 'Admin',
    resource: 'Admin',
  },
  {
    title: 'Acesso',
    route: 'users-list',
    icon: 'UnlockIcon',
    action: 'read',
    resource: 'User',
  },
  {
    title: 'Parceiros',
    route: 'partners-list',
    icon: 'UsersIcon',
    action: 'read',
    resource: 'Partner',
  },
  {
    title: 'FAQ',
    route: 'faq',
    icon: 'HelpCircleIcon',
    action: 'read',
    resource: 'Faq',
  },
  {
    title: 'Contratual',
    route: 'contract-list',
    icon: 'FileTextIcon',
    action: 'read',
    resource: 'Contract',
  },
]
